import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'
import VueAutonumeric from 'vue-autonumeric'
import Moment from 'moment'
import UIkit from 'uikit'
import AxiosRequest from "../utils/axios-request"
import consumer from "../channels/consumer"

# FILTERS
import toCurrency from '../filters/to_currency'

# Local imports
import AddAuction from '../methods/auctions/add_auction'
import Favourite_lot from '../methods/auctions/favourite_lot'

Vue.use TurbolinksAdapter


document.addEventListener 'turbolinks:load', ->
  el = document.getElementById 'show_lot'
  return false unless el?

  {id, title, user, trm} = document.getElementById('initial_lot').dataset
  self = undefined
  new Vue {
    el
    data: ->
      self = this
      {
        lot: []
        users: []
        victim_user: null
        customers: []
        info_max_bid: []
        bid: null
        live: undefined
        my_last_bid: undefined
        first_bid: undefined
        last_lot: undefined
        auction_public: undefined
        admin: undefined
        has_next: undefined
        next: undefined
        has_previus: undefined
        previus: undefined
        image: ''
        image_url: ''
        menssages: undefined
        gallery: []
        histories: []
        countdown: null
        days: null
        interval: null
        expires_in: null
        has_countdow: null
        cable: consumer.subscriptions.create(
          {channel: 'AuctionChannel', auction: id, user}
          {
            connected: ->
              console.info 'Conectado a la subasta...'
              @perform 'connect_user', {user}
            disconnected: ->
              console.info 'Desconectado de la subasta...'
              @perform 'disconnect_user'
            received: (data) =>
              switch data.action
                when 'bid'
                  victim_lot = if @lot.id == data.lot then @lot else ''
                  victim_lot.the_bid.value = data.bid if data.bid
                  victim_lot.the_bid.user = data.user if data.user
                  victim_lot.the_bid.usd = data.usd if data.usd
                  victim_lot.last_bid = data.last_bid if data.last_bid
                  @histories.push data.histories if data.histories
                  @info_max_bid = data.info_max_bid
                  @first_bid = data.first_bid
                  @my_last_bid = data.my_bid
                when 'new_user'
                  if @users.length
                    user = @users.find (user) => user == data.user
                    unless user
                      @users.push(data.user)
                  else
                    @users.push(data.user)
                when 'remove_user'
                  if @users.length
                    user = @users.findIndex (user) => user == data.user
                    @users.splice user, 1
                when 'lot_close'
                  if data.state
                    victim_lot = if @lot.id == data.lot then @lot else ''
                    date_now = new Date();
                    victim_lot.deadline_lot = true
                    victim_lot.deadline = Moment(date_now).format()
                    @expires_in = 0
                    Swal.fire({
                      icon: 'success'
                      title: data.message
                    })
                when 'close_auto'
                  victim_lot = if @lot.id == data.lot then @lot else ''
                  victim_lot.deadline_lot = true
                  @expires_in = 0
                when 'errors'
                  if data.state == false
                    Swal.fire({
                      icon: 'error'
                      title: data.message
                    })
            bid: (lot, user_log) ->
              the_bid = lot.the_bid.value * 1.05
              proxi = Math.ceil(the_bid / 1000) * 1000
              initial = lot.the_bid.value
              bid_automasatic = 0
              # for users logged_in
              if user == user_log
                self.victim_user = parseInt user
              else
                self.victim_user = user_log

              # magic bid
              if self.bid > 0 && self.bid.toString().length > 9
                value = new Intl.NumberFormat({style: 'decimal', maximu4mSignificantDigits: 3 }).format(99999999)
                self.menssages = { message: "Debe ser menor de $ #{value}", type: 'warning' }
                self.bid = 0
              else if self.info_max_bid != null
                if self.info_max_bid.customer_id == self.victim_user && parseInt(self.info_max_bid.customer_bid) < self.bid
                  # si es eque va ganado y quiere subir más la puja
                  if self.bid >= proxi && proxi > initial
                    self.menssages = { message: 'Felicitaciones. has incrementado tu puja máxima', type: 'success' }

                    @perform 'bid', {bid: proxi, user: self.victim_user, lot: lot.id, customer_bid: self.bid, auto: false}
                    self.bid = 0
                  else
                    self.menssages = null
                    value = new Intl.NumberFormat({style: 'decimal', maximu4mSignificantDigits: 3 }).format(proxi)
                    Swal.fire({
                      icon: 'error'
                      title: "Error, la puja debe ser mayor a $ #{value}"
                    })
                else if self.info_max_bid.customer_id != self.victim_user
                  #  alert
                  if self.bid > parseInt self.info_max_bid.customer_bid
                    self.menssages = { message: 'Felicitaciones. tienes la puja máxima', type: 'success' }
                  else if self.bid == parseInt self.info_max_bid.customer_bid
                    self.menssages = { message: 'Has igualado la puja máxima, debes pujar más', type: 'primary' }
                  else
                    self.menssages = { message: 'Tu puja fue superada, debes pujar más', type: 'warning' }
                  # actions
                  if self.bid >= proxi && proxi > initial
                    worth = Math.ceil((self.info_max_bid.customer_bid * 1.05) / 1000) * 1000
                    value = 0
                    if self.bid <= worth
                      value = self.bid
                    else
                      value = worth
                    @perform 'bid', {bid: value, user: self.victim_user, lot: lot.id, customer_bid: self.bid, auto: true}
                    self.bid = 0
                  else
                    self.menssages = null
                    value = new Intl.NumberFormat({style: 'decimal', maximu4mSignificantDigits: 3 }).format(proxi)
                    Swal.fire({
                      icon: 'error'
                      title: "Error, la puja debe ser mayor o igual a $ #{value}"
                    })
                else
                  value = new Intl.NumberFormat({style: 'decimal', maximu4mSignificantDigits: 3 }).format(self.info_max_bid.customer_bid)
                  Swal.fire({
                    icon: 'error'
                    title: "Error, no has superado tu puja máxima $ #{value}"
                  })
              else
                # si no exite ninguna puja
                if self.bid > 0
                  self.menssages = { message: 'Felicitaciones. tienes la puja máxima', type: 'success' }
                  if self.bid >= initial && self.bid <= proxi
                    @perform 'bid', {bid: self.bid, user: self.victim_user, lot: lot.id, customer_bid: self.bid, auto: true}
                  else
                    @perform 'bid', {bid: proxi, user: self.victim_user, lot: lot.id, customer_bid: self.bid, auto: true}
                  self.bid = 0
                else
                  self.menssages = null
                  if self.first_bid
                    value = new Intl.NumberFormat({style: 'decimal', maximu4mSignificantDigits: 3 }).format(proxi)
                  else
                    value = new Intl.NumberFormat({style: 'decimal', maximu4mSignificantDigits: 3 }).format(initial)
                  Swal.fire({
                    icon: 'error'
                    title: "Error, la puja debe ser mayor o igual a $ #{value}"
                  })
            close_lot: (lot, auction, close_auction) ->
              if lot.last_bid
                @perform 'close_lot', { auc: auction, user: lot.last_bid.user, lot: lot.id, bid: lot.last_bid.value, c_auc: close_auction }
              else
                @perform 'close_lot', { auc: auction, user: null, lot: lot.id, bid: 0, c_auc: close_auction }
          }
        )
      }
    mounted: ->
      @fetchLots()
      @SetCountdown()
    methods: {
      AddAuction
      Favourite_lot
      SetCountdown: () ->
        # m = Moment { year :2021, month :3, day :1, hour :15, minute :10, second :3}
        theLoop = =>
          if @expires_in <= 0
            clearInterval @interval
          else
            @expires_in -= 1
            m = Moment.utc(@expires_in * 1000)
            if @days == 0
              @countdown = m.subtract(1, 'seconds').format('00:HH:mm:ss')
            else if m.date() > @days
              @countdown = m.subtract(1, 'days').subtract(1, 'seconds').format('DD:HH:mm:ss')
            else
              @countdown = m.subtract(1, 'seconds').format('DD:HH:mm:ss')
        @interval = setInterval theLoop, 1000
      close_alert: ->
        self.menssages = null
        UIkit.alert('#alert_message').close();
      lot_history: ->
        lot_histories = @histories.slice()
        lot_histories.reverse()
      find_customer: (customer) -> @customers.some (el) => el.customer_id == customer
      fetchLots: ->
        query = $.get "/lots/#{title}-id_#{id}.json"
        query.done (res) =>
          @live = res.live
          @auction_public = res.auction_public
          @admin = res.admin
          @lot = res.lots
          @image = res.image
          @image_url = res.image_url
          @customers = res.customers
          @gallery = res.gallery
          @histories = res.histories
          @last_lot = res.last_lot
          @info_max_bid = res.info_max_bid
          @has_next = res.has_next
          @next = res.next
          @has_previus = res.has_previus
          @previus = res.previus
          @expires_in = res.countdown
          @has_countdow = res.has_countdow
          @days = res.days
          @first_bid = res.first_bid
          @my_last_bid = res.my_last_bid
    }
    computed: {
      puja_usd: ->
        return 0 if self.bid == null
        acum = 0
        if self.bid > 0
          calc = self.bid / parseInt(trm)
          acum = parseFloat(calc).toPrecision(3)
        acum
      bid_minmimum: ->
        bid_automasatic = 0
        the_bid = self.lot.the_bid.value * 1.05
        bid_automasatic = Math.ceil(the_bid / 1000) * 1000
        bid_automasatic
    }
    components: {
      VueAutonumeric
    }
    destroyed: ->
      clearInterval @interval
    filters: {toCurrency}
  }
