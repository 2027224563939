import AxiosRequest from "../../utils/axios-request"

export default (uid, auction) ->
  AxiosRequest("/subastas/add_customer", {auction_id: auction, customer_id: uid}, 'post').then ({data}) =>
    if data.url
      window.location.replace(data.url)
    else
      Swal.fire({
        icon: 'success'
        title: data.message
      })
      location.reload()
