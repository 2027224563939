import AxiosRequest from "../../utils/axios-request"
import SecureLS from 'secure-ls';

export default (uid, lot) ->
  id = parseInt lot.idlot
  idcustomer = uid
  dat = favourite_lot: {lot_id: id, customer_id: idcustomer}
  AxiosRequest('/customers/favourite_lots', dat, 'post').then ({data}) ->
    Swal.fire('Listo', data.message, 'success', timer: 10000).then ->
      console.info 'success'
